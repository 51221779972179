.search-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 8px gray solid !important;
    border-radius: 5px;
}

.search-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 64px;
    z-index: 5;
}

.search-button:hover {
    cursor: pointer;
}

.pointer-on-hover:hover {
    cursor: pointer;
}

.button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    cursor: pointer;
}

.plain-link,
.plain-link:hover {
    color: #212529;
    text-decoration: none;
}

.background {
    background-image: linear-gradient(to top, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.97) 100%), url(https://openmats.s3.amazonaws.com/otherPhotos/background1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}

.background-small {
    background-image: linear-gradient(to top, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.97) 100%), url(https://openmats.s3.amazonaws.com/otherPhotos/background1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
}

/* creates an empty card that will cards fill to the left */
.empty-child-filler {
    width: 220px;
    height: 0px;
}

.card-img-top {
    width:100%;
    height: 10rem;
    object-fit: cover;
}

/* creates an overlay of card if it is currently on hold due to covid */
.covid {
    background-color: red;
    content: 'Currently Paused Due to COVID-19'
}

/*creates a horizontal line with text centered in inner span*/
.hr-centered {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid gray; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
.hr-centered span { 
     background:#fff; 
     padding:0 10px; 
 }

.btn-social{position:relative;padding-left:44px;text-align:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.btn-social>:first-child{position:absolute;left:0;top:0;bottom:0;width:32px;line-height:34px;font-size:1.6em;text-align:center;border-right:1px solid rgba(0,0,0,0.2)}

.btn-facebook{color:#fff;background-color:#3b5998;border-color:rgba(0,0,0,0.2)}.btn-facebook:focus,.btn-facebook.focus{color:#fff;background-color:#2d4373;border-color:rgba(0,0,0,0.2)}
.btn-facebook:hover{color:#fff;background-color:#2d4373;border-color:rgba(0,0,0,0.2)}
.btn-facebook:active,.btn-facebook.active,.open>.dropdown-toggle.btn-facebook{color:#fff;background-color:#2d4373;border-color:rgba(0,0,0,0.2)}.btn-facebook:active:hover,.btn-facebook.active:hover,.open>.dropdown-toggle.btn-facebook:hover,.btn-facebook:active:focus,.btn-facebook.active:focus,.open>.dropdown-toggle.btn-facebook:focus,.btn-facebook:active.focus,.btn-facebook.active.focus,.open>.dropdown-toggle.btn-facebook.focus{color:#fff;background-color:#23345a;border-color:rgba(0,0,0,0.2)}
.btn-facebook:active,.btn-facebook.active,.open>.dropdown-toggle.btn-facebook{background-image:none}
.btn-facebook.disabled:hover,.btn-facebook[disabled]:hover,fieldset[disabled] .btn-facebook:hover,.btn-facebook.disabled:focus,.btn-facebook[disabled]:focus,fieldset[disabled] .btn-facebook:focus,.btn-facebook.disabled.focus,.btn-facebook[disabled].focus,fieldset[disabled] .btn-facebook.focus{background-color:#3b5998;border-color:rgba(0,0,0,0.2)}
.btn-facebook .badge{color:#3b5998;background-color:#fff}

.btn-google{color:#fff;background-color:#dd4b39;border-color:rgba(0,0,0,0.2)}.btn-google:focus,.btn-google.focus{color:#fff;background-color:#c23321;border-color:rgba(0,0,0,0.2)}
.btn-google:hover{color:#fff;background-color:#c23321;border-color:rgba(0,0,0,0.2)}
.btn-google:active,.btn-google.active,.open>.dropdown-toggle.btn-google{color:#fff;background-color:#c23321;border-color:rgba(0,0,0,0.2)}.btn-google:active:hover,.btn-google.active:hover,.open>.dropdown-toggle.btn-google:hover,.btn-google:active:focus,.btn-google.active:focus,.open>.dropdown-toggle.btn-google:focus,.btn-google:active.focus,.btn-google.active.focus,.open>.dropdown-toggle.btn-google.focus{color:#fff;background-color:#a32b1c;border-color:rgba(0,0,0,0.2)}
.btn-google:active,.btn-google.active,.open>.dropdown-toggle.btn-google{background-image:none}
.btn-google.disabled:hover,.btn-google[disabled]:hover,fieldset[disabled] .btn-google:hover,.btn-google.disabled:focus,.btn-google[disabled]:focus,fieldset[disabled] .btn-google:focus,.btn-google.disabled.focus,.btn-google[disabled].focus,fieldset[disabled] .btn-google.focus{background-color:#dd4b39;border-color:rgba(0,0,0,0.2)}
.btn-google .badge{color:#dd4b39;background-color:#fff}

.btn-twitter{color:#fff;background-color:#55acee;border-color:rgba(0,0,0,0.2)}.btn-twitter:focus,.btn-twitter.focus{color:#fff;background-color:#2795e9;border-color:rgba(0,0,0,0.2)}
.btn-twitter:hover{color:#fff;background-color:#2795e9;border-color:rgba(0,0,0,0.2)}
.btn-twitter:active,.btn-twitter.active,.open>.dropdown-toggle.btn-twitter{color:#fff;background-color:#2795e9;border-color:rgba(0,0,0,0.2)}.btn-twitter:active:hover,.btn-twitter.active:hover,.open>.dropdown-toggle.btn-twitter:hover,.btn-twitter:active:focus,.btn-twitter.active:focus,.open>.dropdown-toggle.btn-twitter:focus,.btn-twitter:active.focus,.btn-twitter.active.focus,.open>.dropdown-toggle.btn-twitter.focus{color:#fff;background-color:#1583d7;border-color:rgba(0,0,0,0.2)}
.btn-twitter:active,.btn-twitter.active,.open>.dropdown-toggle.btn-twitter{background-image:none}
.btn-twitter.disabled:hover,.btn-twitter[disabled]:hover,fieldset[disabled] .btn-twitter:hover,.btn-twitter.disabled:focus,.btn-twitter[disabled]:focus,fieldset[disabled] .btn-twitter:focus,.btn-twitter.disabled.focus,.btn-twitter[disabled].focus,fieldset[disabled] .btn-twitter.focus{background-color:#55acee;border-color:rgba(0,0,0,0.2)}
.btn-twitter .badge{color:#55acee;background-color:#fff}
